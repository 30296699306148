import './App.css';
import { getMonth } from "./util";
import React, { useState, useContext, useEffect } from "react";
import Month from "./components/Month";
import CalendarHeader from "./components/CalendarHeader";
import MonthHeader from './components/MonthHeader';
import GlobalContext from "./context/GlobalContext";
import EventModal from "./components/EventModal";

function App() {

  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal, userEmail } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);



  return (
    <React.Fragment>

      {showEventModal && <EventModal/>}

      <div className="h-screen flex flex-col">
        <CalendarHeader />
        <MonthHeader month={currenMonth} />
        <div className="flex flex-1">
          <Month month={currenMonth} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
