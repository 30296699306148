import React from 'react'
import Day from "./Day";

export default function MonthHeader({ month }) {
  return (
    <div className="bg-blue-200 grid grid-cols-7 grid-rows-1">
      {month[0].map((day, i) => (
        <span key={i} className="text-sm mt-1 font-bold text-center">
          {day.format("ddd").toUpperCase()}
        </span>
      ))}
    </div>
  )
}
