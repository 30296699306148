import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    userEmail,
  } = useContext(GlobalContext);

  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.eventdate).format("DD-MM-YY") === day.format("DD-MM-YY")
    );

    // Sort day events according to event time - AM follows by PM
    const sortedDayEvents = events.sort(function(a,b){
        return String(a.eventtime).localeCompare(b.eventtime)});
    
    setDayEvents(sortedDayEvents);
  
  }, [filteredEvents, day]);

  function getNonCurrentMonthClass() {
    return day.format("MM") !== dayjs().format("MM")
      ? "bg-gray-100"
      : "";
  }

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-blue-600 text-white rounded-full w-7"
      : "";
  }

  function getEventtimeClass(evt) {
    let _class = ''
    if(evt.eventtime === 'Full') _class = ' p-12 '
    if(evt.eventtime === 'AM') _class = ' p-4 '
    if(evt.eventtime === 'PM') _class = ' p-4 '
    if(userEmail && evt.createdby === userEmail) _class += 'bg-blue-600 text-white'
    else _class += 'bg-blue-200 text-grey-600' 
    return _class
  }

  function getAMClass(evt, s) {
    if(s.length === 1 && evt.eventtime === 'PM') return 'p-4 ml-3 mr-3 text-gray-600 text-sm rounded mb-2 truncate'
    return ''
  }
  
  function getAMSpace(evt, s) {
    if(s.length === 1 && evt.eventtime === 'PM') return '\u00A0'
    return ''
  }

  return (
    <div className={`border border-gray-200 flex flex-col ${getNonCurrentMonthClass()}`}>
      <header className="flex flex-col items-center">
        <p
          className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      
      {!userEmail
      ? <div className="flex-1">
        {dayEvents.map((evt, idx, s) => (
          <>
          <div className={`${getAMClass(evt,s)}`}>{getAMSpace(evt,s)}</div>
          
          <div
            key={idx}
            className={`${getEventtimeClass(evt)} ml-3 mr-3 text-sm text-center rounded mb-2 truncate`}
          >
            &nbsp;
          </div>
          </>
        ))}
      </div>
      :
      <div className="flex-1 cursor-pointer"
        
        onClick={() => { 
          
            setDaySelected(day);
            setShowEventModal(true);
        }}
       >

        {dayEvents.map((evt, idx, s) => (
          <>
          <div className={`${getAMClass(evt,s)}`}>{getAMSpace(evt,s)}</div>
          <div
            key={idx}
            onClick={() => setSelectedEvent(evt)}
            className={`${getEventtimeClass(evt)} ml-3 mr-3 text-sm text-center rounded mb-2 truncate`}
          >
            {evt.title}
          </div>
          </>
        ))}
        
      </div>
    }
    </div>
  );
}
