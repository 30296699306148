import React, {
    useState,
    useEffect,
    useReducer,
    useMemo,
} from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_TEC_APP_SERVER_URL ? process.env.REACT_APP_TEC_APP_SERVER_URL: "http://localhost:8000" ;
const SERVER_URL_CAL_PATH = '/api/cal';

function savedEventsReducer(state, { type, payload }) {
    switch (type) {
        case "push":
            //console.log("push:" + JSON.stringify(payload))
            return [...state, payload];
        case "update":
            //console.log("update:" + JSON.stringify(payload))
            return state.map((evt) =>
                evt._id === payload._id ? payload : evt
            );
        case "delete":
            return state.filter((evt) => evt._id !== payload._id);
        case "load":
            //console.log("load:" + JSON.stringify(payload))
            return payload
        default:
            throw new Error();
    }
}

function initEvents() {

    let parsedEvents = []
    return parsedEvents;
}

export default function ContextWrapper(props) {

    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
    const [daySelected, setDaySelected] = useState(dayjs());
    const [showEventModal, setShowEventModal] = useState(false);
    const [initialLoadingMessage, setInitialLoadingMessage] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [savedEvents, dispatchCalEvent] = useReducer(
        savedEventsReducer,
        [],
        initEvents
    );
    const [userEmail, setUserEmail] = useState('');
    const [userToken, setUserToken] = useState('');

    const filteredEvents = useMemo(() => {
        return savedEvents
    }, [savedEvents]);


    useEffect(() => {
        const initFetch = async () => {            
            const req = await axios.get(SERVER_URL+SERVER_URL_CAL_PATH)
            dispatchCalEvent({ type: 'load', payload: req.data })
            setShowEventModal(false)
            setInitialLoadingMessage(false)
        }
        setShowEventModal(true)
        setInitialLoadingMessage(true)
        initFetch()
    }, [])

    useEffect(() => {
        if (smallCalendarMonth !== null) {
            setMonthIndex(smallCalendarMonth);
        }
    }, [smallCalendarMonth]);

    useEffect(() => {
        if (!showEventModal) {
            setSelectedEvent(null);
        }
    }, [showEventModal]);

    return (
        <GlobalContext.Provider
            value={{
                monthIndex,
                setMonthIndex,
                smallCalendarMonth,
                setSmallCalendarMonth,
                daySelected,
                setDaySelected,
                showEventModal,
                setShowEventModal,
                dispatchCalEvent,
                selectedEvent,
                setSelectedEvent,
                savedEvents,
                filteredEvents,
                userEmail,
                setUserEmail,
                userToken,
                setUserToken,
                initialLoadingMessage
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
}
