import React from "react";

const GlobalContext = React.createContext({
    monthIndex: 0,
    setMonthIndex: (index) => { },
    smallCalendarMonth: 0,
    setSmallCalendarMonth: (index) => { },
    daySelected: null,
    setDaySelected: (day) => { },
    showEventModal: false,
    setShowEventModal: () => { },
    dispatchCalEvent: ({ type, payload }) => { },
    savedEvents: [],
    selectedEvent: null,
    setSelectedEvent: () => { },
    filteredEvents: [],
    userName: null,
    userEmail: null,
    setUserEmail: (email) => {},
    userToken: null,
    setUserToken: (email) => {},
    initialLoadingMessage: true
});

export default GlobalContext;
