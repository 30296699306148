import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_TEC_APP_SERVER_URL ? process.env.REACT_APP_TEC_APP_SERVER_URL : "http://localhost:8000";
const ADMIN = process.env.REACT_APP_ADMIN ? process.env.REACT_APP_ADMIN : "admin@ibm.com";
const SERVER_URL_CAL_PATH = '/api/cal';
const SERVER_URL_EVENTS_PATH = '/api/events';

export default function EventModal({ day }) {
  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
    filteredEvents,
    userEmail,
    userToken,
    initialLoadingMessage
  } = useContext(GlobalContext);

  const [dayEvents, setDayEvents] = useState([]);
  const [disableFull, setDisableFull] = useState(false);
  const [disableAM, setDisableAM] = useState(false);
  const [disablePM, setDisablePM] = useState(false);

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showFull, setShowFull] = useState(false);

  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.title : ""
  );
  const [_id, setId] = useState(
    selectedEvent ? selectedEvent._id : ""
  );
  const [_rev, setRev] = useState(
    selectedEvent ? selectedEvent._rev : ""
  );
  const [eventdate, setEventdate] = useState(
    selectedEvent ? selectedEvent.eventdate : ""
  )
  const [eventtime, setEventtime] = useState(
    selectedEvent ? selectedEvent.eventtime : ""
  )


  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.eventdate).format("DD-MM-YY") === dayjs(daySelected).format("DD-MM-YY")
    );

    if (events.length === 2) {
      setDisableFull(true)
      setShowFull(true)
      if (selectedEvent && selectedEvent.eventtime === 'AM') {
        setDisableAM(false)
        setDisablePM(true)
      }
      if (selectedEvent && selectedEvent.eventtime === 'PM') {
        setDisableAM(true)
        setDisablePM(false)
      }
    } else if (events.length === 1) {
      if (events[0].eventtime === 'Full') {
        setShowFull(true)
      } else if (events[0].eventtime === 'AM') {
        setDisableFull(true)
        if (!selectedEvent) setDisableAM(true)
      } else {
        setDisableFull(true)
        if (!selectedEvent) setDisablePM(true)
      }
    } else {
      setShowFull(false)
    }
    setDayEvents(events);

  }, [filteredEvents, day]);

  const [createdby, setCreatedby] = useState(
    selectedEvent ? selectedEvent.createdby : userEmail
  );

  function handleChange() {
    if (document.getElementById("eventtime").value !== "" && document.getElementById("title").value.trim() !== "") {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }

  function handleSubmit(e, action) {
    e.preventDefault();

    const calendarEvent = {
      title,
      eventdate: daySelected.valueOf(),
      eventtime: document.getElementById("eventtime").value,
      createdby: userEmail,
      _id: selectedEvent ? selectedEvent._id : "",
      _rev: selectedEvent ? selectedEvent._rev : ""
    };

    const config = {
      headers: { Authorization: `Bearer ${userToken}` }
    };

    switch (action) {
      case "save":
        if (selectedEvent) {
          axios.post(SERVER_URL + SERVER_URL_EVENTS_PATH, calendarEvent, config)
            .then(res => {
              let result = calendarEvent
              result._id = res.data._id
              result._rev = res.data._rev
              dispatchCalEvent({ type: "update", payload: result });
            })
        } else {
          axios.post(SERVER_URL + SERVER_URL_EVENTS_PATH, calendarEvent, config)
            .then(res => {
              let result = calendarEvent
              result._id = res.data._id
              result._rev = res.data._rev
              dispatchCalEvent({ type: "push", payload: result });
            })
        }
        break;
      case "delete":
        axios.delete(SERVER_URL + SERVER_URL_EVENTS_PATH, { headers: { Authorization: `Bearer ${userToken}` }, data: selectedEvent })
          .then(res => {
            let result = calendarEvent
            result._id = res.data._id
            result._rev = res.data._rev
            dispatchCalEvent({
              type: "delete",
              payload: selectedEvent,
            });
          })
        break;
      default:
        console.log("No dispatch triggered.")
        break;

    }
    setShowEventModal(false);

  }
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center">
      {initialLoadingMessage
        ? <>
          <div className="bg-white rounded-lg shadow-2xl w-1/4">
            <header className="bg-blue-800 px-4 py-2 flex justify-between items-center">
              <span>
                &nbsp;
              </span>

            </header>

            <div className="p-3"><div className="grid items-end gap-y-10"><p className="px-4">Please wait while application is loading ...</p></div></div>
          </div>
        </>

        : <form className="bg-white rounded-lg shadow-2xl w-1/4">
          <header className="bg-blue-800 px-4 py-2 flex justify-between items-center">
            <span className="material-icons-outlined text-white">
              drag_handle
            </span>
            <div>
              <button onClick={() => setShowEventModal(false)}>
                <span className="material-icons-outlined text-white">
                  close
                </span>
              </button>
            </div>
          </header>
          {showFull && !selectedEvent
            ? <div className="p-3"><div className="grid items-end gap-y-10"><p className="px-4">Technical Exploration Center is fully reserved for the day.</p></div></div>
            :
            <div className="p-3">
              <div className="grid grid-cols-1/5 items-end gap-y-10">
                <span className="material-icons-outlined text-gray-600">title</span>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Add title (Mandatory)"
                  value={title}
                  required
                  className="pt-3 text-gray-600 font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                  onChange={(e) => { setTitle(e.target.value); handleChange() }}
                />
                
                <span className="material-icons-outlined text-gray-600">
                  schedule
                </span>
                <div>
                  {daySelected.format("dddd, MMM DD")} - {selectedEvent && selectedEvent.eventtime}

                  <select id="eventtime" name="eventtime" className="mr-2 ml-2 text-gray-600" defaultValue={selectedEvent && selectedEvent.eventtime} onChange={(e) => { setEventtime(e.target.value); handleChange() }}>
                    {!selectedEvent && <option></option>}
                    {disableFull && <option value="Full" disabled>Full Day</option>}{!disableFull && <option value="Full">Full Day</option>}
                    {disableAM && <option value="AM" disabled>AM</option>}{!disableAM && <option value="AM">AM</option>}
                    {disablePM && <option value="PM" disabled>PM</option>}{!disablePM && <option value="PM">PM</option>}
                  </select>

                </div>
                <span className="material-icons-outlined text-gray-600">
                  segment
                </span>
                <span>Reserved By: {selectedEvent && selectedEvent.createdby}{!selectedEvent && userEmail}</span>
              </div>
            </div>
          }

          {(() => {
            if (selectedEvent) {
              if (userEmail === selectedEvent.createdby) {
                return (<footer className="flex justify-end border-t p-3 mt-5">
                  {selectedEvent && userEmail === selectedEvent.createdby && (
                    <button
                      onClick={(e) => handleSubmit(e, "delete")}
                      className="bg-red-500 hover:bg-red-600 px-6 py-2 mx-2 rounded text-white"
                    >
                      Delete
                    </button>
                  )}
                  {showSaveButton && userEmail === selectedEvent.createdby && (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e, "save")}
                      className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                    >
                      Save
                    </button>
                  )}
                  {!showSaveButton && userEmail === selectedEvent.createdby && (
                    <button
                      type="button"
                      className="bg-gray-500 px-6 py-2 rounded text-white cursor-default"
                    >
                      Save
                    </button>
                  )}
                </footer>)
              } else {
                return (<footer className="flex justify-end border-t p-3 mt-5">
                  <button className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white" onClick={() => setShowEventModal(false)}>
                    Close
                  </button>
                </footer>)
              }
            } else {

              if (showFull) {
                return (<footer className="flex justify-end border-t p-3 mt-5">
                  <button className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white" onClick={() => setShowEventModal(false)}>
                    Close
                  </button>
                </footer>)
              } else {
                return (<footer className="flex justify-end border-t p-3 mt-5">
                  {showSaveButton && userEmail && (
                    <button
                      type="submit"
                      onClick={(e) => handleSubmit(e, "save")}
                      className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                    >
                      Save
                    </button>
                  )}
                  {!showSaveButton && userEmail && (
                    <button
                      type="button"
                      className="bg-gray-500 px-6 py-2 rounded text-white cursor-default"
                    >
                      Save
                    </button>
                  )}
                </footer>)
              }
            }
          })()}
        </form>
      }
    </div>
  );
}
