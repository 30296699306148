import dayjs from "dayjs";
import React, { useContext } from "react";
import logo from "../assets/schedule.svg";
import GlobalContext from "../context/GlobalContext";
import AppID from 'ibmcloud-appid-js';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_TEC_APP_SERVER_URL ? process.env.REACT_APP_TEC_APP_SERVER_URL : "http://localhost:8000";
const SERVER_URL_EVENTS_PATH = '/api/events';
const APPID_CLIENTID = process.env.REACT_APP_APPID_CLIENTID ? process.env.REACT_APP_APPID_CLIENTID : "";
const APPID_DISCOVERY_ENDPOINT = process.env.REACT_APP_APPID_DISCOVERY_ENDPOINT ? process.env.REACT_APP_APPID_DISCOVERY_ENDPOINT : "";

export default function CalendarHeader() {

  const appID = React.useMemo(() => {
    return new AppID()
  }, []);

  (async () => {
    try {
      await appID.init({
        clientId: APPID_CLIENTID,
        discoveryEndpoint: APPID_DISCOVERY_ENDPOINT
      });
    } catch (e) {
      setErrorState(true);
      setErrorMessage(e.message);
    }
  })();

  const { monthIndex, setMonthIndex, setShowEventModal, dispatchCalEvent, userEmail, setUserEmail, userToken, setUserToken } = useContext(GlobalContext);
  const [errorState, setErrorState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }

  const loginAction = async () => {
    try {
      const tokens = await appID.signin();
      setErrorState(false);
      setLoginButtonDisplayState(false);
      setWelcomeDisplayState(true);
      setUserName(tokens.idTokenPayload.name);
      setUserEmail(tokens.idTokenPayload.email);
      setUserToken(tokens.accessToken)

      // Reload events with Authentication Bearer
      let URL = SERVER_URL + SERVER_URL_EVENTS_PATH;
      const config = {
        headers: { Authorization: `Bearer ${tokens.accessToken}` }
      };
      const req = await axios.get(URL, config)
      dispatchCalEvent({ type: 'load', payload: req.data })
    } catch (e) {
      setErrorState(true);
      setErrorMessage(e.message);
    }
  };

  const [welcomeDisplayState, setWelcomeDisplayState] = React.useState(false);
  const [loginButtonDisplayState, setLoginButtonDisplayState] = React.useState(true);
  const [userName, setUserName] = React.useState('');

  return (
    <header className="px-4 py-2 flex items-center border">
      <img src={logo} alt="calendar" className="mr-2 w-12 h-12" />
      <h1 className="mr-10 text-xl fond-bold">
        IBM Singapore - Technical Exploration Center (TEC)
      </h1>
      <button
        onClick={handleReset}
        className="border rounded py-2 px-4 mr-5 bg-blue-600 text-white shadow-md"
      >
        Today
      </button>
      <button onClick={handlePrevMonth}>
        <span className="material-icons-outlined cursor-pointer shadow-md bg-white text-gray-600 mx-2">
          chevron_left
        </span>
      </button>
      <button onClick={handleNextMonth}>
        <span className="material-icons-outlined cursor-pointer shadow-md bg-white text-gray-600 mx-2">
          chevron_right
        </span>
      </button>
      <h2 className="ml-4 text-xl font-bold px-4">
        {dayjs(new Date(dayjs().year(), monthIndex)).format(
          "MMMM YYYY"
        )}
      </h2>
      {userEmail && <><p className="mr-10 text-xs underline">[<a href="https://github.ibm.com/asean-techgarage/ibm-sg-tec-reservation-app/issues/new" target="_self">Report issue</a>]</p><p className="absolute right-0 mr-10 text-xl fond-bold"> Welcome {userName}</p></>}
      {loginButtonDisplayState && <button onClick={loginAction} className="border rounded py-2 px-4 mr-5 bg-blue-600 text-white shadow-md absolute right-0">Login</button>}
    </header>
  );
}
